<template>
    <b-modal title="Stranka" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group label="Naziv">
                    <validation-provider #default="{ errors }" name="Naziv" rules="required">
                        <b-form-input v-model="object.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Naslov">
                    <validation-provider #default="{ errors }" name="Naslov" rules="required">
                        <b-form-input v-model="object.address"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Alias">
                    <validation-provider #default="{ errors }" name="Alias" rules="required">
                        <b-form-input v-model="object.alias"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Davčna številka">
                    <validation-provider #default="{ errors }" name="tax_number" rules="required|numeric|min:8|max:10">
                        <b-form-input v-model="object.tax_number"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Zaključek dneva">
                    <validation-provider #default="{ errors }" name="closing_time" rules="required">
                        <b-form-timepicker v-model="object.closing_time"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Verzija">
                    <validation-provider #default="{ errors }"  name="tier" rules="required">
                        <v-select v-model="object.tier" :reduce="ele => ele.id" :clearable="false" :options="tiers" appendToBody/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Prodajalec">
                    <validation-provider #default="{ errors }" name="prodajalec">
                        <v-select v-model="object.reseller_id" label="name" :reduce="x => x.id" :clearable="true" :options="resellers" appendToBody/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group label="Davčni zavezanec">
                    <b-form-checkbox switch v-model="object.is_tax_payer"/>
                </b-form-group>
                <b-form-group label="Onemogočen">
                    <b-form-checkbox switch v-model="object.disabled"/>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">Uredi</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">Dodaj</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox, BFormTimepicker} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, min, max, numeric } from '@validations'
    import {Tiers} from '@/libs/enums/Tiers'
    import vSelect from 'vue-select'


    export default {
        components: {
            vSelect,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            BFormTimepicker,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            resellers: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                tiers: [
                    {
                        label: 'Essential',
                        id: Tiers.Essential
                    },
                    {
                        label: 'Standard',
                        id: Tiers.Standard
                    },
                    {
                        label: 'Premium',
                        id: Tiers.Premium
                    }
                ],
                modalActive: false,
                object: {
                    name: '',
                    address: '',
                    alias: '',
                    tax_number: '',
                    closing_time: '04:00:00',
                    is_tax_payer: false,
                    disabled: false,
                    tier: Tiers.Essential
                },
                editing: false,
                required,
                min,
                max,
                numeric
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.object = JSON.parse(JSON.stringify(data))
                    this.editing = true
                    this.object.closing_time = this.$dayjs(this.object.closing_time).utc().format('HH:mm:00')
                } else {
                    this.editing = false
                    this.object =  {
                        name: '',
                        address: '',
                        alias: '',
                        tax_number: null,
                        closing_time: '04:00:00',
                        is_tax_payer: false,
                        disabled: false,
                        tier: Tiers.Essential,
                        reseller_id: ''
                    }
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    const timestampString = `1970-01-31T${this.object.closing_time}.000Z`
                    const timestamp = this.$dayjs(timestampString)

                    const tmpObject = {
                        name: this.object.name,
                        address: this.object.address,
                        alias: this.object.alias,
                        closing_time: timestamp,
                        tax_number: Number(this.object.tax_number),
                        is_tax_payer: this.object.is_tax_payer,
                        disabled: this.object.disabled,
                        tier: this.object.tier,
                        reseller_id: this.object.reseller_id
                    }
                    const response = await this.$http.post('/api/admin/v1/client/', tmpObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Dodajanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        address: '',
                        closing_time: '04:00:00',
                        user_ids: [],
                        tier: Tiers.Essential
                    }
                } catch {
                    this.$printError('Prišlo je do napake')
                }
            },
            async edit() {
                try {
                    const timestampString = `1970-01-31T${this.object.closing_time}.000Z`
                    const timestamp = this.$dayjs(timestampString)

                    const tmpObject = {
                        name: this.object.name,
                        address: this.object.address,
                        alias: this.object.alias,
                        tax_number: Number(this.object.tax_number),
                        closing_time: timestamp,
                        is_tax_payer: this.object.is_tax_payer,
                        disabled: this.object.disabled,
                        tier: this.object.tier,
                        reseller_id: this.object.reseller_id
                    }

                    const response = await this.$http.put(`/api/admin/v1/client/${this.object.id}`, tmpObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess('Urejanje je bilo uspešno!')
                    this.object = {
                        name: '',
                        address: '',
                        closing_time: '04:00:00',
                        user_ids: [],
                        tier: Tiers.Essential
                    }
                } catch {
                    this.$printError('Prišlo je do napake')
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
